.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #66c3fe;
}

.send-icon {
  background-color: white;
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 50%;
  padding: 0.35em 0.35em;
  top: -1px;
  right: -1px;
}

.trash-icon {
  cursor: pointer;
}
